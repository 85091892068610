<template>
    
        <vue-apex-charts type="pie"
                         :height="600"
                         :options="chartOptions"
                         :series="series"
                         :key="mudouSerie"></vue-apex-charts>    
</template>
<script>
     import {Ranking} from "../../../services/Arena.js";
    const rk = new Ranking;

    import VueApexCharts from "vue-apexcharts";
   
    export default {
            name: "RankingGeralGraph",
      data: () => ({
            title: "RankingGeralGraph",            
          RankingList: [],
          series: [0, 0, 0, 0, 0, 0],
          mudouSerie: false,
            chartOptions: {
               labels: ['0,00 a 1,99','2,00 a 2,49','2,50 a 2,99','3,00 a 3,49','3,50 a 3,99','4,00 a 5,00',],
               colors: ['#e84c41', '#eb867f', '#eb7821', '#dade0d', '#f6f797', '#f6f797'],
               responsive: [{
                    //breakpoint: 480,
                    options: {
                         chart: {
                      //        width: 400
                         },
                         legend: {
                              position: 'bottom'
                         }
                    }
               }]
          }                
        }),        
       components: {   
        VueApexCharts
  },
       props: {            
           pais: { type: Number },
           
        },
        computed: {          
        },              
        created() {  
                rk.Get(this.pais, 0, 0,0).then((resp) => {
                this.RankingList = resp;
                this.SetGraphData()                
                    }, (err) => alert(err));   
        },
        methods:
        {
            SetGraphData()
            {                                       
                //calular reparti��o
                for (var i = 0; i < this.RankingList.length; i++)
                {                    
                    if (this.RankingList[i].nota_geral_estadio <= 1.99)
                        this.series[0]++;
                    else {
                        if (this.RankingList[i].nota_geral_estadio <= 2.49)
                            this.series[1]++;
                        else {
                            if (this.RankingList[i].nota_geral_estadio <= 2.99)
                                this.series[2]++;
                            else {
                                if (this.RankingList[i].nota_geral_estadio <= 3.49)
                                    this.series[3]++;
                                else {
                                    if (this.RankingList[i].nota_geral_estadio <= 4.00)
                                        this.series[4]++;
                                    else {
                                        if (this.RankingList[i].nota_geral_estadio <= 5.00)
                                            this.series[5]++;
                                    }
                                }
                            }
                        }
                    }
                }    

                this.mudouSerie = !this.mudouSerie;
            } 
        }
};
</script>
