<template>
  <vs-row vs-justify="center">       
    <vs-col vs-lg="12" vs-xs="12" vs-sm="12">
      <vs-card>
        <!-- Header -->
          <div class="d-md-flex align-items-center">
              <!--<div>
      <h3 class="card-title">Projects of the Month</h3>
      <h5 class="card-subtitle text-muted font-weight-normal">Overview of Latest Month</h5>
    </div>-->
              <div class="ml-auto">
                 
                  <vs-select class="w-150px" v-model="filter1">
                      <vs-select-item :key="index"
                                      :value="item.value"
                                      :text="item.text"
                                      v-for="(item,index) in filteroptions1" />
                  </vs-select>
              </div>
          </div>
        <RankingGeralGraph :pais="filter1" :series="series" :key="filter1+series"></RankingGeralGraph>
      </vs-card>
    </vs-col>  
  </vs-row>
</template>
<script>
// //////////////////////////////////////////
// Import Components Here
// //////////////////////////////////////////    
    
import RankingGeralGraph from "../Ranking/GeralGraph.vue"

 import {Surveys} from "../../../services/Arena.js";
    const sv = new Surveys;

   
// //////////////////////////////////////////
// Export Here
// //////////////////////////////////////////
export default {
  name: "Surveys",
  data: () => ({
    title: "Surveys",
    // Month Table
    filter1: 0,
    filteroptions1: [            
      ]
  }),
  components: {
    RankingGeralGraph,    
        },
        created() {
            this.setSideBar(true);
            this.filteroptions1 = [];
            this.filteroptions1.push({ text: "Todos os paises", value: 0 });                            
            sv.GetCountries().then((resp) => {                
                for (var i = 0; i < resp.length; i++)
                    {
                    var payload = {'text': resp[i].countryNameOriginal, 'value': resp[i].countryId };
                    this.filteroptions1.push(payload);                
                }              

                                            
            }, (err) => alert(err));

            
        },
        methods: {
             setSideBar(val) {
                 this.$store.commit("TOGGLE_REDUCE_SIDEBAR", val);
            },
           
        }
};
</script>